import React, { useState } from "react";
import schema from './website.png';

const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const title = "<post human monsters>";
  const text = "";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { human, landscape, death } = e.target.elements;
    let details = {
      human: human.value,
      landscape: landscape.value,
      death: death.value,
    };
    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    alert(result.status);
  };
  return (
    <div>
      <div className="content half">
      <div className="animation half">
        <img src={schema}></img>

        </div>
        <h1>{title}</h1>
        <div>
          <p>On the verge of climate and planetary catastrophe, humans were forced to leave their physical existence and became digital data encoded in the DNA of landscape (minerals, plants, soil,..), the ultimate ecological form of data storage. These posthuman beings exist as Data at Rest, Data in Transit and Data in Use. The Data at Rest represents the static characteristics, memories and knowledge of the posthuman being stored in a vessel. The being is Data in Transit when it is moving through the network of vessels of posthuman data. It becomes Data in Use when it is interacting with another vessel. In the state of interaction, the datas are merged into new temporary vessels, so the posthuman beings are permanently becoming one or many others</p>
          <p>The transition from analog to digital, from body to data, favored the emergence of an egalitarian society. As humans were freed from their sexualized, racialized, queer, homosexual and/or disabled bodies, they were no longer bound by the old definitions and standards of human and otherness. At the same time, other forms of data that would never be considered human in the physical world (like programs) exist as equal posthuman beings. 
          By turning over the fate of human existence to nature (the posthuman beings literally exist on it), the humans lost their anthropocentric pedestal. The natural bodies (animals, landscape, bacteria, minerals) are released from human exploitation. They now live in a symbiotic relationship where the human is no longer a parasite. The natural structure itself cannot be owned, property is a human concept, and it has no purpose in profit.</p> 
          <p>As digital data, the posthuman being exists permanently; it can be repeated and reproduced, it even exists not as one but as many. It has no decision on ending its existence, at the same time that its same existence is fundamentally irrelevant. The posthuman no longer fears death. It misses it.</p>
          </div>
      </div>
    
    <form onSubmit={handleSubmit} className="half">
      <h1>Would disembodiment really reflect in the emergence of such an equalitarian and ecological society?</h1>
      <p>Imagine yourself as a vessel in this posthuman network.<span> You don't have to reflect on all the subjects. Choose as you like. You can write in english, portuguese, spanish or german.</span></p>
      <div>
        <p className="label" htmlFor="human">How would disembodiment affect your relation with other beings?</p>
        <textarea rows="6" type="text" id="human"/>
      </div>
      <div>
        <p className="label" htmlFor="landscape">How would you feel about the analog reality/landscape?</p>
        <textarea rows="6" type="email" id="landscape"/>
      </div>
      <div>
        <p className="label" htmlFor="death">How do you feel about death?</p>
        <textarea rows="6" id="death"/>
      </div>

      <div>
        <p className="label" htmlFor="message">Select some of these topics and describe how do you feel about them</p>
        <ul>
          <li>Deterriotization</li>
          <li>Encoding</li>
          <li>Decoding</li>
          <li>Body</li>
          <li>Bits</li>
          <li>Unnatural</li>
          <li>Fluid</li>
          <li>Gender</li>
          <li>Sexuality</li>
          <li>Nomadic</li>
          <li>Birth</li>
          <li>Becoming</li>
          <li>External</li>
          <li>Divide</li>
          <li>Cyborg</li>
          <li>Race</li>
          <li>Subjective</li>
          <li>Reproduce</li>
          <li>Node</li>
          <li>Organism</li>
          <li>Privilege</li>
          <li>Amalgam</li>
        </ul>
        <div>
        <p className="label" htmlFor="message">Suggest others</p>
        <textarea rows="1" id="suggest" />
      </div>
        <textarea rows="6" id="message" />

      </div>
      <p><span>By submiting you agree that I use your answers as content for training a text generation A.I model as part of my master thesis research Posthuman Monsters.</span></p>
      <button type="submit">{status}</button>
    </form>
    </div>
  );
};

export default ContactForm;